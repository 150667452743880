import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import GithubIcon from '@mui/icons-material/GitHub';
import "../Styles/Home.css";

const Home = () => {
  return (
    <div className="home">
      <div className='about'>
        <h2>Hi, My Name is Dylan Pipping</h2>
        <div className='prompt'>
          <p>
          I'm a Media Designer with an added passion for Game Design.
          </p>
          <LinkedInIcon />
          <EmailIcon />
          <GithubIcon />
        </div>
      </div>
      <div className="skills">

        <h1>Skills</h1>
        <ol className="list">
          <li className="item">
            <h2>Front-End</h2>
            <span>
              ReactJS, HTML, CSS, TailwindCSS,
            </span>
          </li>
          <li className="item">
            <h2>Languages</h2>
            <span>
              Javascript, Typescript, C#,
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home