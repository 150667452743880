import React from 'react';
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GithubIcon from "@mui/icons-material/GitHub";
import "../Styles/Footer.css";


const Footer = () => {
  return (
    <div className='footer'>
        <div className='socialMedia'>
            <InstagramIcon />
            <LinkedInIcon />
            <GithubIcon />
        </div>
        <p> &copy; 2023 portfoliosemester6-dylan.nl</p>
    </div>
  )
}

export default Footer