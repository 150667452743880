import projectPlan from "../assests/projectplan.jpg";
import researchDocument from "../assests/reading.png";
import readingGuide from "../assests/researchicon.png";
import kosen from "../assests/1.png";
import alfheim from "../assests/3.jpg";
import characterModel from "../assests/4.jpg";
import frontyr from "../assests/frontyr.jpg";
import endgame from "../assests/gun.png";
import webshop from "../assests/Huistijl.jpg";
import kana from "../assests/Kana.png";
import regards from "../assests/owl.png";

export const ProjectList = [
    {
        name: "Project Plan",
        image: projectPlan,
        skill: "Research",
        content: "",
    },
    {
        name: "Research Document",
        image: researchDocument,
        skill: "Research",
        content: "",
    },
    {
        name: "Reading Guide",
        image: readingGuide,
        skill: "Research",
        content: "",
    },
    {
        name: "Kosen",
        image: kosen,
        skill: "Unity, C#",
        content: "",
    },
    {
        name: "Alfheim",
        image: alfheim,
        skill: "Unity, C#",
        content: "",
    },
    {
        name: "Character Model",
        image: characterModel,
        skill: "Blender",
        content: "",
    },
    {
        name: "Frontyr",
        image: frontyr,
        skill: "Adobe XD",
        content: "",
    },
    {
        name: "Endgame",
        image: endgame,
        skill: "Unity, Blender, C#",
        content: "",
    },
    {
        name: "My Easy Webshop",
        image: webshop,
        skill: "Html, CSS, PHP",
        content: "",
    },
    {
        name: "What the Kana?",
        image: kana,
        skill: "Adobe XD",
        content: "",
    },
    {
        name: "Kind Regards,",
        image: regards,
        skill: "Unity, Blender, Research, C#, animations",
        content: "",
    },
];