import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@mui/icons-material/School";

const Experience = () => {
  return (
  <div className="experience">
    <VerticalTimeline lineColor="#3e497a">
      <VerticalTimelineElement className="vertical-timeline-element--education" date="2015 - 2019" iconStyle={{background: "#3e497a", color: "#fff"}} icon={<SchoolIcon />}>
        <h3 className="vertical-timeline-element-title">Summa College Laboratorium, Eindhoven, Noord-Brabant, Nederland</h3>
        <p>Diploma Chemisch-Fysisch Analist</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement className="vertical-timeline-element--education" date="2019 - Present" iconStyle={{background: "#3e497a", color: "#fff"}} icon={<SchoolIcon />}>
        <h3 className="vertical-timeline-element-title">Fontys Hogescholen HBO-ICT, Eindhoven, Noord-Brabant, Nederland</h3>
        <p>Propodeuse Diploma</p>
        <p>Bachelor's Degree -- Not Yet Achieved</p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  </div>
  );
}

export default Experience